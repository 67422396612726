import { forwardRef, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import mileLogo from 'Assets/mile_logo.svg'
import porscheLogo from 'Assets/Porsche_wordmark_black_rgb.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {LogoContainer} from './styles'
import {
  Button,
  Dialog,
  DialogActions,
  Slide
} from '@mui/material'
import { type TransitionProps } from '@mui/material/transitions'
import { themeColors } from '../../themes'
import { isMobile } from 'react-device-detect'
import {isPorsche } from "../../Utils/envCheck";
import {HeadingSmallStyle, TextSmallStyle} from "Containers/App/styles";

const logo = isPorsche() ? porscheLogo : mileLogo;

const Transition = forwardRef(function Transition (
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function MenuAppBar () {
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const START_HOURS = 6
  const END_HOURS = 18

  const today = new Date()
  // not sunday nor saturday
  const isWeekDay = today.getDay() !== 0 || today.getDay() !== START_HOURS
  const isOfficeHour = today.getHours() >= 9 && today.getHours() <= END_HOURS
  const isPhoneAvailable = isWeekDay && isOfficeHour

  return (
    <Box>
      <AppBar
        position="static"
        style={{
          display: 'flex',
          flex: 1,
          borderRadius: isPorsche() ? 4 : 8,
          backgroundColor: 'white'
        }}
      >
        <Toolbar
          variant={'dense'}
          style={{
            display: 'grid',
            gridGap: '2em',
            gridTemplateColumns: '1fr max-content 1fr',
            gridTemplateAreas: "'left center right'",
            minHeight: '36px',
            width: '100%'
          }}
        >
          <div
            style={{
              gridArea: 'left',
              justifySelf: 'start',
              color: isPorsche() ? themeColors.porsche.success : themeColors.mile.success
            }}
          >
            <FontAwesomeIcon
              icon={['fas', 'bars']}
            />
          </div>
          <LogoContainer>
            <img
              style={{
                minHeight: '36px',
                width: 'calc(100vw / 2.8)',
                minWidth: '25mm',
                padding: isPorsche() ? 'unset' : '10px 0'
              }}
              src={logo} alt="logo" />
          </LogoContainer>
          <div
            style={{
              gridArea: 'right',
              justifySelf: 'end',
              color: isPorsche() ? themeColors.porsche.success : themeColors.mile.success
            }}
          >
            <FontAwesomeIcon
              aria-controls="call-menu"
              aria-haspopup="true"
              onClick={handleClickOpen}
              icon={['fas', 'phone']}
            />
          </div>
        </Toolbar>

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <div
            className="p-2 dialog-content"
          >
            <HeadingSmallStyle
            >
              Contact
            </HeadingSmallStyle>
            <TextSmallStyle>
              <p className="mb-2">
                Our {isPorsche() ? 'Customer Concierge' : 'Customer Care'} team is available Monday through Friday from 9
                AM to 6 PM across the continental United States.
              </p>
              {!isPhoneAvailable && (
                <p>
                  If you would like to contact our team outside of those hours,
                  you may email us by clicking below.
                </p>
              )}
            </TextSmallStyle>
          </div>
          <DialogActions
          >
            <Button
              className="call-button"
              href={isPorsche() ? 'tel:18004004128' : 'tel:18886453001' }
              onClick={handleClose}
              disabled={!isPhoneAvailable}
            >
              Via Call
            </Button>
            <Button
              className="email-button"
              href={isPorsche() ? 'mailto:customercare@porscheautoinsurance.com' : 'mailto:customercare@mileauto.com'}
              onClick={handleClose}
            >
              Via Email
            </Button>
          </DialogActions>
        </Dialog>
      </AppBar>
    </Box>
  )
}
