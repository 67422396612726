import { type FC, type ReactNode } from 'react'
import {isPorsche} from "../../Utils/envCheck";
import {ContentStyles} from "./styles";
import {themeColors} from "../../themes";

interface BoxedContentProps {
  children?: ReactNode | ReactNode[]
  disabled?: boolean
}

export const BoxedContent: FC<BoxedContentProps> = ({
  children,
  disabled = false
}) => {
  const styles = disabled
    ? {
      color: isPorsche() ? themeColors.porsche.secondary : themeColors.mile.secondary,
      'border-color': isPorsche() ? themeColors.porsche.secondary : themeColors.mile.secondary,
    } : {
      color: isPorsche() ? themeColors.porsche.primary : themeColors.mile.success,
      'border-color': isPorsche() ? themeColors.porsche.primary : themeColors.mile.success,
    }

  return (
    <div
      className={`px-7 py-3 rounded-lg border-2 justify-center items-center mb-4`}
      style={styles}
    >
      {children}
    </div>
  )
}
