import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { Wrapper } from '../styles'
import handsImage from 'Assets/mvod-hands.png'
import {useContext, useEffect, useRef, useState} from 'react'
import { MVODStateContext } from 'Services/stateProvider'
import {HeadingXLargeStyle, TextMediumStyle} from "Containers/App/styles";
import {isPorsche} from "../../Utils/envCheck";
import {themeColors} from "../../themes";

const TakePhoto = () => {

  const {
    state: {
      selectedTask: task,
      uploadImage: image
    },
    updateStateItem
  } = useContext(MVODStateContext)
  const navigate = useNavigate()
  const imageUpload = useRef<HTMLInputElement>(null);
  const [captureSrc, setCaptureSrc] = useState<string | null>(null);

  useEffect(() => {
    if (task && !task.id) navigate('/')
    if (captureSrc && image) navigate('/camera')
  }, [task, image, captureSrc])

  const uploadButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!imageUpload || !imageUpload.current) return;

    imageUpload.current.click();
  }

  const handleCapture = (target:HTMLInputElement) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        setCaptureSrc(newUrl);
        updateStateItem({ uploadImage: file });
      }
    }
  }

  return (
    <Wrapper>
      <div className="flex flex-col items-center justify-center max-w-[500px]">
        <div className="mx-4">
          <HeadingXLargeStyle className="text-center">
            Get ready...
          </HeadingXLargeStyle>

          <TextMediumStyle className="text-center">
            Let's take a photo of the odometer of your <b style={{whiteSpace: 'nowrap'}}>{task?.vehicle.name || 'vehicle'}</b>. When
            you’re ready, tap the button to
            open your camera.
          </TextMediumStyle>
        </div>

        <img
          src={handsImage}
          alt="hands with a phone"
          className="w-[100vw] max-w-[500px]"
        />
        <TextMediumStyle className="text-center">Remember to rotate your phone to capture the entire dashboard
          with your odometer clearly displayed.</TextMediumStyle>
        <input
          accept='image/*'
          id="icon-button-file"
          type="file"
          capture="environment"
          hidden
          aria-hidden
          ref={imageUpload}
          onChange={(e) => handleCapture(e.target)}
        />
        <label htmlFor="icon-button-file">
          { isPorsche() ?
            (
              <Button
                aria-label="upload picture"
                variant="contained"
                style={{
                  borderRadius: '4px',
                  backgroundColor: themeColors.porsche.primary
                }}
                onClick={uploadButtonClick}
              >
                Take a Photo
              </Button>
            ) : (
            <Button
              aria-label="upload picture"
              className="w-[90%] max-w-[250px]"
              variant="contained"
              onClick={uploadButtonClick}
            >
              Take a photo
            </Button>
            )
          }
        </label>
      </div>
    </Wrapper>
  )
}

export default TakePhoto
