import { useNavigate } from 'react-router-dom'
import {BrowserView, isMobile} from 'react-device-detect'
import {useRef, useState, useEffect, useContext, ChangeEvent} from 'react'
import { Button, IconButton, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { CloseOutlined, PhotoCamera } from '@mui/icons-material/'
import { uploadImageToS3 } from 'Utils/uploadImageToS3'
import { useUpdateTaskMutation } from 'Store/Task'
import { appKey } from 'Constants/keys'
import {
  VideoWrapper,
  MediaContainer,
  TopOverlay,
  CloseOverlay,
  GuideOverlay,
  ButtonContainer,
  CameraPreview
} from './styles'
import exifr from 'exifr'
import { MVODStateContext } from 'Services/stateProvider'
import { useGetOrientation } from 'Hooks/useGetOrientation'
import * as Sentry from '@sentry/react'
import {TaskType} from "../../Typings/state";
import {themeColors} from "../../themes";
import {isPorsche} from "../../Utils/envCheck";
import {TextMediumStyle} from "Containers/App/styles";

const videoConstraints = {
  width: { min: window.screen.availHeight },
  height: { min: window.screen.availWidth },
  facingMode: 'environment'
}

const Camera = () => {
  const {
    state: {
      selectedTask: task,
      uploadImage: image
    },
    updateStateItem
  } = useContext(MVODStateContext)
  const imageRef = useRef<HTMLImageElement>(null)
  const imageUpload = useRef<HTMLInputElement>(null);
  const navigate = useNavigate()
  const {
    data: updateTaskResult,
    error,
    execute: updateTask
  } = useUpdateTaskMutation()
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [captureSrc, setCaptureSrc] = useState<string | null>(null);
  const [showCapture, setShowCapture] = useState<boolean>(false);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [fileDataURL, setFileDataURL] = useState<string>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState(image)
  const [fileType, setFileType] = useState<string>()
  const [odoValue, setOdoValue] = useState<string>()
  const { getOrientation } = useGetOrientation()
  const isLandscape = getOrientation === 'landscape'
  const handleCapture = (target:HTMLInputElement) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        setCaptureSrc(newUrl);
      }
    }
  }
  const postCameraStyle = {
    paddingBottom: (isLandscape && isMobile) ? '2.5rem' : 0,
    rotate: (isLandscape && isMobile) ? '270deg' : '0deg'
  }
  const postCameraButtonStyle = {
    rotate: (isLandscape && isMobile) ? '270deg' : '0deg',
    position: (isLandscape && isMobile) ? 'absolute' as const : undefined,
    right: (isLandscape && isMobile) ? 0 : 'unset',
    bottom: (isLandscape && isMobile) ? '40%' : 'unset'
  }

  const handleUploadClick = () => {
    if (imageUpload && imageUpload.current) {
      imageUpload.current.click();
    }
  }
  const dataURItoBlob = (dataURI:string) => {
    const byteString = window.atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const imageMimeType = /image\/(png|jpg|jpeg)/i;
    if (e.target.files) {
      const file = e.target.files[0];
      if (!file.type.match(imageMimeType)) {
        updateStateItem({
          toastMessage: {
            message: 'An error occurred. Please try again.',
            color: 'error'
          }
        })
      } else {
        setFileType(file.type)
      }
      updateStateItem({ uploadImage: file })
    }
  }

  useEffect(() => {
    (async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((i) => i.kind == 'videoinput');
      setDevices(videoDevices);
    })();
  });

  useEffect(() => {
    if (!image && !captureSrc) {
      navigate(-1)
    }
    let fileReader: FileReader;
    let isCancel = false
    if (image) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        if (!e.target || !e.target.result) {
          throw new Error("FileReader onload event missing result")
        }

        const { result } = e.target
        const strResult = typeof(result) === "string" ? result : result.toString();

        if (result && !isCancel) {
          setFileDataURL(strResult)
        }
      }
      fileReader.readAsDataURL(image)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [image])

  useEffect(() => {
    if (error) {
      updateStateItem({
        toastMessage: {
          message: 'An error occurred. Please try again.',
          color: 'error'
        }
      })
      resetCameraView()
    } else if (updateTaskResult) {
      updateStateItem({
        toastMessage: {
          message: 'We received your photo! Please allow up to 24 hours for verification.',
          color: 'success'
        }
      })
      if (task) navigate('/')
    }
  }, [updateTaskResult])

  const resetCameraView = () => {
    setCaptureSrc(null)
    updateStateItem({ uploadImage: null })
    navigate(-1)
  }

  const handleClose = () => {
    navigate('/photo')
  }
  const updateODOValue = (value: string) => {
    setOdoValue(value)
  }

  const completeUpload = (urlToUpload: string, taskTypeToUpload: TaskType) => {
    setIsUploadingImage(true)
    uploadImageToS3({ blobData: dataURItoBlob(urlToUpload), task: taskTypeToUpload, fileType: fileType })
      .then((data) => {
        exifr.parse(urlToUpload).then((exif) => {
          updateTask({
            key: appKey,
            id: taskTypeToUpload.id,
            input_results: [
              {
                index: 1,
                photo_url: data.Location,
                photo_metadata: exif ?? {}
              },
              {
                index: 2,
                text: odoValue
              }
            ],
            location: {
              latitude: 0.0,
              longitude: 0.0
            },
            close_task: true
          })
              .then(() => {
                updateStateItem({
                  toastMessage: { message: 'Your photo was uploaded successfully!', color: 'success' }
                })
                setIsUploadingImage(false)
                navigate('/success')
              })
              .catch((err) => {
                updateStateItem({
                  toastMessage: err
                })
                console.error(err)
                Sentry.captureException(err, {level: "fatal"});
              })
        })
      })
      .catch((err) => {
        updateStateItem({
          toastMessage: err
        })
        console.error(err)
        Sentry.captureException(err, {level: "fatal"});
      })
      // TODO: decide if we need this line
      // .finally(() => setIsUploadingImage(false));
  };

  const uploadToS3AndUpdateTask = () => {

    if (task && captureSrc) {
      completeUpload(captureSrc, task);
    } else if (task && fileDataURL) {
      completeUpload(fileDataURL, task);
    }
  }

  return (
    <VideoWrapper>
      {!isMobile && !image ? (
        <>
          <BrowserView>
            <input accept='image/*' type='file' onChange={handleFileChange} ref={imageUpload} style={{ display: 'none' }} />
          </BrowserView>
          <ButtonContainer className="z-10 flex justify-between" style={{
            bottom: isLandscape ? '40%' : 'unset'
          }}>
            <Button
              onClick={handleUploadClick}
              variant="outlined"
              color="success"
              startIcon={<PhotoCamera />}
            >
              Upload Image
            </Button>
          </ButtonContainer>
        </>
      ) : (
        <></>
      )}
      {isUploadingImage
        ? (
        <>
          <CircularProgress />
          <p>Processing...</p>
        </>
          )
        : (
        <>
          {(isMobile && !image) && !captureSrc && (
            <TopOverlay className="top-overlay">
              <CloseOverlay>
                <IconButton onClick={handleClose}>
                  <CloseOutlined fontSize="large" color="primary" />
                </IconButton>
              </CloseOverlay>
              <GuideOverlay>
                <Typography
                  color={'white'}
                  variant="subtitle1"
                  style={{
                    borderRadius: 8,
                    padding: 10,
                    backgroundColor: '#15151580',
                    margin: 10
                  }}
                >
                  Rotate your phone, position the digits of your odometer in the center, then click "Snap Photo".
                </Typography>
              </GuideOverlay>
            </TopOverlay>
          )}
          {image
            ? (
            <div className="flex flex-col" style={postCameraStyle}>
              <div className="flex justify-center items-center z-10">
                <img
                  src={fileDataURL}
                  alt="preview"
                  style={{
                    maxWidth: '75vw',
                    maxHeight: '50vh'
                  }}
                />
              </div>
              <div className="flex flex-col justify-center items-center z-10 my-2">
                <TextMediumStyle className="backdrop-blur-md text-center rounded-md px-4">
                  Type your odometer reading in the box.
                </TextMediumStyle>
                <input
                  className="backdrop-blur-md bg-white text-center rounded-md outline-primary border-2 border-primary"
                  style={{
                    borderColor: isPorsche() ? themeColors.porsche.primary : themeColors.mile.success,
                    color: isPorsche() ? themeColors.porsche.primary : themeColors.mile.primary
                  }}
                  value={odoValue}
                  inputMode="numeric"
                  type="number"
                  placeholder="Odometer Reading"
                  onChange={(e) => { updateODOValue(e.target.value) }}
                />
              </div>
            </div>
              )
            : captureSrc
              ? (
            <div className="flex flex-col" style={postCameraStyle}>
              <div className="flex justify-center items-center z-10">
                <img className={isLandscape ? 'w-2/3' : 'w-3/4'} ref={imageRef} src={captureSrc} alt="capturedImage" />
              </div>
              <div className="flex flex-col justify-center items-center z-10 my-2">
                <p className="backdrop-blur-md text-center rounded-md px-4">
                  Type your odometer reading in the box.
                </p>
                <input
                    className="backdrop-blur-md bg-white text-center rounded-md outline-primary border-2 border-primary"
                    value={odoValue}
                    inputMode="numeric"
                    type="number"
                    placeholder="Odometer Reading"
                    onChange={(e) => { updateODOValue(e.target.value) }}
                />
              </div>
            </div>
                )
              : (
                <MediaContainer>
                  <input
                    accept='image/*'
                    id="icon-button-file"
                    type="file"
                    capture="environment"
                    onChange={(e) => handleCapture(e.target)}
                  />
                  <CameraPreview
                    image={captureSrc}
                    onClick={() => {
                      setShowCapture(!showCapture)
                    }}
                  />
                </MediaContainer>
              )}
          {isMobile && !image && !captureSrc
            ? (
              <div className="absolute bottom-16 flex flex-col justify-center items-center">
            </div>
              )
            : !isMobile && !image ? (<></>) : (
            <ButtonContainer className="z-10 min-w-fit flex justify-between" style={postCameraButtonStyle}>
              <Button
                  onClick={resetCameraView}
                  variant="outlined"
                  style={{
                    color: isPorsche() ? themeColors.porsche.primary : themeColors.mile.success,
                    outlineColor: isPorsche() ? themeColors.porsche.primary : themeColors.mile.success,
                    borderRadius: '4px',
                    margin: '0 1rem'
                  }}
                  startIcon={<PhotoCamera />}
              >
                Re-take
              </Button>
              <Button
                onClick={uploadToS3AndUpdateTask}
                variant="contained"
                disabled={!odoValue}
                style={{
                  color: isPorsche() ? themeColors.porsche.textPrimary : themeColors.mile.secondary,
                  borderRadius: '4px',
                  margin: '0 1rem'
                }}
              >
                Done
              </Button>
            </ButtonContainer>
          )}
        </>
      )}
    </VideoWrapper>
  )
}

export default Camera
