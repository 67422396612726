import { createTheme } from '@mui/material/styles';

export const themeColors = {
  mainGreen: '#009966',
  lightBlue: '#E8F4F8',
  red: '#CD3427',
  darkBlue: '#183B56',
  grayBlue: '#5A7184',
  success: '#00852a',
  primary: '#29313b',
  secondary: '#475c70',
  mile: {
    primary:'#29313b',
    secondary: '#475c70',
    success: '#009966',
    error: '#CD3427',
    background:'#E8F4F8'
  },
  mverity: {
    primary: '#',
    success: '#00852a',
    background: '#dad9de'
  },
  porsche: {
    light: '#dad9de',
    primary: '#000',
    secondary: '#313639',
    success: '#d5001c',
    error: '#d5001c',
    background: '#eff0f1',
    textPrimary: '#fff',
    textSecondary: '#000',
    buttonSecondary: '#313639',
    buttonPrimary: '#d5001c'
  }
}

export const defaultTheme = createTheme({
  typography: {
    fontFamily: 'Open Sans',
    h1: {
      fontSize: '4.5vh',
      '@media (orientation: landscape)': {
        fontSize: '4.2vw'
      },
      fontWeight: 700,
      color: themeColors.primary
    },
    subtitle1: {
      fontSize: '2vmax',
      fontWeight: 400,
      color: themeColors.primary
    },
    subtitle2: {
      fontSize: '1.6vmax',
      fontWeight: 400,
    },
    button: {
      fontSize: '2.3vmax',
      fontWeight: 600,
      textTransform: 'none'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: '0px 34px 74px -30px rgba(0, 0, 0, 0.15)'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          ':hover': {
            border: '2px solid',
          },
          textTransform: 'none',
          borderRadius: 4,
          border: '1px solid',
          // fontSize: '1.125rem',
          fontWeight: 600
        },
      }
    }
  },
  palette: {
    primary: {
      main: themeColors.success,
    },
    secondary: {
      main: themeColors.secondary,
    },
    error: {
      main: themeColors.red,
    },
    background: {
      paper: themeColors.lightBlue
    },
    text: {
      primary: themeColors.darkBlue,
      secondary: themeColors.grayBlue,
    }
  },
});
export const porscheTheme = createTheme({
  typography: {
    fontFamily: 'PorscheNextRegular, Arial Narrow,Arial,sans-serif',
    h1: {
      fontSize: '4.5vh',
      '@media (orientation: landscape)': {
        fontSize: '4.2vw'
      },
      fontWeight: 700,
      color: themeColors.porsche.primary
    },
    subtitle1: {
      fontSize: '2vmax',
      fontWeight: 400,
      color: themeColors.porsche.primary
    },
    subtitle2: {
      fontSize: '1.6vmax',
      fontWeight: 400,
    },
    button: {
      fontSize: '2.3vmax',
      fontWeight: 600,
      textTransform: 'none'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: '0px 34px 74px -30px rgba(0, 0, 0, 0.15)'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: themeColors.porsche.textPrimary,
          backgroundColor: themeColors.porsche.primary,
          borderRadius: 4,
          border: `0px`
        },
        outlined: {
          ':hover': {
            border: '2px solid',
          },
          textTransform: 'none',
          borderRadius: 4,
          border: '1px solid',
          // fontSize: '1.125rem',
          fontWeight: 600
        },
      }
    }
  },
  palette: {
    primary: {
      main: themeColors.porsche.primary,
    },
    secondary: {
      main: themeColors.porsche.secondary,
    },
    error: {
      main: themeColors.porsche.error,
    },
    background: {
      paper: themeColors.porsche.background
    },
    text: {
      primary: themeColors.porsche.textPrimary,
      secondary: themeColors.porsche.textSecondary,
    }
  },
});
export const mileTheme = createTheme({
  typography: {
    fontFamily: 'Hanken Grotesk, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,sans-serif',
    h1: {
      fontSize: '4.5vh',
      '@media (orientation: landscape)': {
        fontSize: '4.2vw'
      },
      fontWeight: 700,
      color: themeColors.darkBlue
    },
    subtitle1: {
      fontSize: '2vmax',
      fontWeight: 400,
      color: themeColors.grayBlue
    },
    subtitle2: {
      fontSize: '1.6vmax',
      fontWeight: 400,
    },
    button: {
      fontSize: '2.3vmax',
      fontWeight: 600,
      textTransform: 'none'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: '0px 34px 74px -30px rgba(0, 0, 0, 0.15)'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          ':hover': {
            border: '2px solid',
          },
          textTransform: 'none',
          borderRadius: 4,
          border: '1px solid',
          // fontSize: '1.125rem',
          fontWeight: 600
        },
      }
    }
  },
  palette: {
    primary: {
      main: themeColors.mainGreen,
    },
    secondary: {
      main: themeColors.lightBlue,
    },
    error: {
      main: themeColors.red,
    },
    background: {
      paper: themeColors.mile.background
    },
    text: {
      primary: themeColors.darkBlue,
      secondary: themeColors.grayBlue,
    }
  },
});

// muiTheme = responsiveFontSizes(muiTheme)
export default { defaultTheme, mileTheme, porscheTheme, themeColors }