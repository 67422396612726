import styled from '@emotion/styled'
import { themeColors } from 'themes'
import { isPorsche } from "../../Utils/envCheck";
import {
  displaySmallStyle,
  displayLargeStyle,
  displayMediumStyle,
  gridGap,
  headingLargeStyle,
  headingMediumStyle,
  headingSmallStyle,
  headingXLargeStyle,
  headingXXLargeStyle,
  spacingFluidMedium,
  textLargeStyle,
  textMediumStyle,
  textSmallStyle,
  textXLargeStyle,
  textXSmallStyle,
  textXXSmallStyle,
  themeLightPrimary,
} from '@porsche-design-system/components-react/styles';
import {PButton} from "@porsche-design-system/components-react";

// Wrapper
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: gridGap,
  padding: spacingFluidMedium,
  height: '100vh',
  backgroundColor: isPorsche() ? themeColors.porsche.background : themeColors.mile.background
});

// Typography
const typographyBaseStyle = {
  color: themeLightPrimary,
  margin: 0,
  fontFamily: !isPorsche() ? 'Hanken Grotesk !important' : undefined,
} as const;

const Heading = styled.h3({
  ...typographyBaseStyle,
  ...headingMediumStyle,
});

// Display
const DisplayLargeStyle = styled.h1({
  ...typographyBaseStyle,
  ...displayLargeStyle,
});

const DisplayMediumStyle = styled.h2({
  ...typographyBaseStyle,
  ...displayMediumStyle,
});

const DisplaySmallStyle = styled.h2({
  ...typographyBaseStyle,
  ...displaySmallStyle,
});

// Heading
const HeadingXXLargeStyle = styled.h2({
  ...typographyBaseStyle,
  ...headingXXLargeStyle,
});

const HeadingXLargeStyle = styled.h3({
  ...typographyBaseStyle,
  ...headingXLargeStyle,
});

const HeadingLargeStyle = styled.h4({
  ...typographyBaseStyle,
  ...headingLargeStyle,
});

const HeadingMediumStyle = styled.h5({
  ...typographyBaseStyle,
  ...headingMediumStyle,
});

const HeadingSmallStyle = styled.h6({
  ...typographyBaseStyle,
  ...headingSmallStyle,
});

// Text
const TextXLargeStyle = styled.p({
  ...typographyBaseStyle,
  ...textXLargeStyle,
});

const TextLargeStyle = styled.p({
  ...typographyBaseStyle,
  ...textLargeStyle,
});

const TextMediumStyle = styled.p({
  ...typographyBaseStyle,
  ...textMediumStyle,
});

const TextSmallStyle = styled.p({
  ...typographyBaseStyle,
  ...textSmallStyle,
});

const TextXSmallStyle = styled.p({
  ...typographyBaseStyle,
  ...textXSmallStyle,
});

const TextXXSmallStyle = styled.p({
  ...typographyBaseStyle,
  ...textXXSmallStyle,
});

const Container = styled.div({
  padding: '20px 30px',
  color: isPorsche() ? themeColors.porsche.primary : themeColors.mile.primary
})

const ContentContainer = styled.div({
  margin: '40px 0px',
  color: isPorsche() ? themeColors.porsche.primary : themeColors.mile.primary
})

export {
  Wrapper,
  Heading,
  DisplayLargeStyle,
  DisplayMediumStyle,
  DisplaySmallStyle,
  HeadingXXLargeStyle,
  HeadingXLargeStyle,
  HeadingLargeStyle,
  HeadingMediumStyle,
  HeadingSmallStyle,
  TextXLargeStyle,
  TextLargeStyle,
  TextMediumStyle,
  TextSmallStyle,
  TextXSmallStyle,
  TextXXSmallStyle,
  Container,
  ContentContainer
}