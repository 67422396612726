import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './Containers/App'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import {defaultTheme, mileTheme, porscheTheme} from './themes'
import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import { MVODStateProvider } from 'Services/stateProvider'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import * as Sentry from '@sentry/react'
import {BrowserOptions} from "@sentry/react";
import {AppHost, isAppHost} from "./Typings/strings";

const sentryConfigurations: Record<AppHost, Partial<BrowserOptions>> = {
  MileAuto: {
    dsn: 'https://f7799e046e3b5cfe3799cd5fa4f3c5bb@o345548.ingest.sentry.io/4506197865594880'
  },
  Porsche: {
    dsn: 'https://bcef83939e031dda7f2d7738ab04898e@o345548.ingest.sentry.io/4506197883617280'
  }
}

const currentHost = process.env.REACT_APP_HOST

if (!currentHost) {
  throw new Error("Missing env var: REACT_APP_HOST")
}

if (!isAppHost(currentHost)) {
  throw new Error("REACT_APP_HOST must be set to MileAuto or Porsche");
}

const currentConfig = sentryConfigurations[currentHost]

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    tracesSampleRate: 1,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay()
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ...currentConfig
  })
}

library.add(fas)

ReactDOM.render(
  <React.StrictMode>
    <MVODStateProvider>
      <MuiThemeProvider theme={currentHost && currentHost.includes('Porsche') ? porscheTheme : mileTheme}>
        <Router>
          <App />
        </Router>
      </MuiThemeProvider>
    </MVODStateProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
