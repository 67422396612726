import styled from '@emotion/styled'
import { themeColors } from '../../themes'

export const LogoContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  color: themeColors.primary,
  gridArea: 'center',
  justifySelf: 'center',
  alignItems: 'center',
  minWidth: '25mm'
})