import styled from '@emotion/styled';
import {isPorsche} from "../../Utils/envCheck";
import {themeColors} from "../../themes";

export const VideoWrapper = styled.div({
  top: 0,
  left: 0,
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10,
  backgroundColor: isPorsche() ? themeColors.porsche.background : themeColors.mile.background
})

export const MediaContainer = styled.div({
  position: 'absolute',
  top: 0
})

export const ButtonContainer = styled.div({
  bottom: 0,
  margin: 30,
  '@media(orientation: landscape)': {
    margin: '0 0 5px'
  }
})

export const CloseOverlay = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  zIndex: 12
})

export const TopOverlay = styled.div({
  top: 0,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  position: 'absolute'
})

export const GuideOverlay = styled.div({
  marginTop: -12,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  zIndex: 12
})

export const CameraPreview = styled.div<{ image: string | null }>`
  width: calc(100vw - 50px);
  height: calc(100vh - 50px);
  padding-top: 50px;
  ${({ image }) => (image ? `background-image:  url(${image});` : '')}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 500px) {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
  }
  @media (max-width: 800px) {
    width: calc(100vw - 30px);
    height: calc(100vh - 30px);
  }
  @media (max-width: 1000px) {
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
  }
`;