import { type FC } from 'react'
import deadline from 'Assets/deadline.svg'
import redWarning from 'Assets/red-warning.svg'
import menu from 'Assets/menu.svg'
import check from 'Assets/check.svg'
import warning from 'Assets/warning.svg'
import clock from 'Assets/clock.svg'
import {TextSmallStyle} from "Containers/App/styles";

interface FeedbackMessageProps {
  message: string
  type?: 'Pending' | 'LongDeadline' | 'ShortDeadline' | 'PastDeadline' | 'Error' | 'Info' | 'Success' | 'InProgress'
}

export const FeedbackMessage: FC<FeedbackMessageProps> = ({
  message,
  type = 'Pending'
}) => {
  let styles = 'Pending'
  let icon = menu

  switch (type) {
    case 'Pending':
      styles = 'text-info-600'
      icon = clock
      break
    case 'InProgress':
      styles = 'text-info-600'
      icon = menu
      break
    case 'ShortDeadline':
      styles = 'text-error-600'
      icon = deadline
      break
    case 'PastDeadline':
      styles = 'text-error-600'
      icon = redWarning
      break
    case 'Success':
      styles = 'text-success-600'
      icon = check
      break
    case 'Info':
      styles = 'text-info-600 bg-info-200 rounded-lg p-2'
      icon = warning
      break
    case 'Error':
      styles = 'text-error-600 bg-[#CD342720] rounded-lg p-2'
      icon = redWarning
      break
  }

  return (
    <div className={`flex text-sm font-normal mb-4 items-center ${styles}`}>
      <img className="mr-2" src={icon} alt={`${type}-icon`} />
      <TextSmallStyle>{message}</TextSmallStyle>
    </div>
  )
}
