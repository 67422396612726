import { type NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import { useGetTaskListQuery } from 'Store/Task'
import { BoxedContent } from 'Components/BoxedContent'
import { FeedbackMessage } from 'Components/FeedbackMessage'
import { type ErrorType, type ListType, type TaskType } from 'Typings/state'
import { useContext } from 'react'
import { MVODStateContext } from 'Services/stateProvider'
import { differenceInCalendarDays, format, intervalToDuration, isAfter, isBefore } from 'date-fns'
import {
  HeadingLargeStyle,
  HeadingXLargeStyle,
  TextMediumStyle,
  TextSmallStyle,
  TextXSmallStyle
} from "Containers/App/styles";
import {themeColors} from "../../themes";

const Home = () => {
  const { updateStateItem } = useContext(MVODStateContext)
  const navigate: NavigateFunction = useNavigate()
  const [searchParams] = useSearchParams()
  const key: string = searchParams.get('key') || ''
  const { data: taskData, isFetching, error } = useGetTaskListQuery(key)
  const apiError: ErrorType = error as ErrorType

  const handleTaskClicked = (task: TaskType) => {
    updateStateItem({ selectedTask: task })
    navigate('/photo')
  }
  const dueText = (task: TaskType) => {
    const today: Date = new Date()
    const dueDate: Date = new Date(task.due)

    // If due after today:
    if (isAfter(dueDate, today)) {
      // If due more than 1 day from now, pluralize 'day'
      if (
        differenceInCalendarDays(
          dueDate, today
        ) > 1
      ) {
        return `Due in ${differenceInCalendarDays(
          dueDate, today
        )} days. Please send a photo before ${format(dueDate, 'hh:mmb eeee, LLL do')}.`
      }
      // If due 1 day from now, singularize 'day'
      if (
        differenceInCalendarDays(
          dueDate, today
        ) === 1
      ) {
        // but check if less than 1 day!!!
        if (
          intervalToDuration({
            start: new Date(),
            end: new Date(task.due)
          }).hours! < 23
        ) {
          return `Due in ${intervalToDuration({
                  start: new Date(),
                  end: new Date(task.due)
              }).hours} hours. Please send a photo before ${format(new Date(task.due), 'hh:mmb eeee, LLL do')}.`
        }
        return `Due in ${differenceInCalendarDays(
            dueDate, today
          )} day. Please send a photo before ${format(new Date(task.due), 'hh:mmb eeee, LLL do')}.`
      }

    // If not due after today (due today or overdue):
    } else {
      // if late, still allow submission, but NAUGHTY NAUGHTY!!!
      return 'Uh-oh, your photo is overdue! Please send it immediately.'
    }

    return ''
  }

  const taskStyle = (due: Date) => {
    if (
      isAfter(new Date(due), new Date()) &&
        intervalToDuration({
          start: new Date(),
          end: new Date(due)
        }).days || 1 > 1
    ) {
      return 'Pending'
    }

    if (
      isAfter(new Date(due), new Date()) &&
        intervalToDuration({
          start: new Date(),
          end: new Date(due)
        }).days === 1
    ) {
      return 'ShortDeadline'
    }

    if (
      isBefore(new Date(due), new Date())
    ) {
      return 'PastDeadline'
    }
  }

  if (apiError?.data || !key || key === "" || key === null) {
    navigate('/index')
  }

  return (
    <div>
      {isFetching
        ? (
        <CircularProgress />
          )
        : apiError?.data || !key
          ? (
            <HeadingLargeStyle style={{color:themeColors.porsche.error}}>{apiError?.data?.message}</HeadingLargeStyle>
            )
          : taskData && (!taskData.tasks || taskData.tasks?.length === 0)
            ? (
              <HeadingLargeStyle>No tasks available!</HeadingLargeStyle>
              )
            : (
        <>
          <div className="mt-0">
            <HeadingXLargeStyle
              style={{
                textAlign: 'center'
              }}>It's Photo Time!</HeadingXLargeStyle>
            <div className="text-center text-normal text-md font-normal">
              <TextSmallStyle>{taskData?.dashboard_text}</TextSmallStyle>
            </div>
          </div>

          {(taskData as ListType)?.tasks.map((task, index) => (
            <div className="mt-8" key={`task-${task.vehicle.name}-${index}`}>
              <BoxedContent>
                <div
                  className="text-center text-lg font-semibold"
                  onClick={() => { handleTaskClicked(task) }}
                >
                  <TextMediumStyle className="font-bold">{task.vehicle.name}</TextMediumStyle>
                  <TextXSmallStyle className="opacity-50">VIN: {task.vehicle.vin}</TextXSmallStyle>
                </div>
              </BoxedContent>
              <FeedbackMessage
                type={taskStyle(task.due)}
                message={dueText(task)}
              />
            </div>
          ))}
        </>
              )}
    </div>
  )
}

export default Home
